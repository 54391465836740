








































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import GoodsPane from '@/components/goods/goods-pane.vue'
import ExportData from '@/components/export-data/index.vue'
import { RequestPaging } from '@/utils/util'
import { apiGoodsLists, apiGoodsOtherList } from '@/api/goods'
import { GoodsType } from '@/utils/type'
@Component({
    components: {
        GoodsPane,
        ExportData
    }
})
export default class Goods extends Vue {
    queryObj = {
        keyword: '',
        category_id: '',
        supplier_id: ''
    }
    supplierList: any[] = []
    categoryList: any[] = []
    tabCount = {
        all_count: 0,
        sales_count: 0,
        sellout_count: 0,
        storage_count: 0,
        warning_count: 0
    }
    pager = new RequestPaging()
    activeName: any = 'all'
    apiGoodsLists = apiGoodsLists
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiGoodsLists,
                params: {
                    type: GoodsType[this.activeName],
                    ...this.queryObj
                }
            })
            .then((res: any) => {
                this.tabCount = res?.extend
            })
    }
    handleReset() {
        this.queryObj = {
            keyword: '',
            category_id: '',
            supplier_id: ''
        }
        this.getList()
    }

    getGoodsOtherList() {
        apiGoodsOtherList({
            type: 'list'
        }).then((res: any) => {
            this.categoryList = res?.category_list
            this.supplierList = res?.supplier_list
        })
    }
    created() {
        this.getGoodsOtherList()
        this.getList()
    }
}
